import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularFireDatabase, FirebaseListObservable } from 'angularfire2/database';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { LoaderService } from '../loader.service';
declare var $: any;
declare var google: any;
declare var require: any;
const geofire = require('geofire');

@Component({
  selector: 'app-create-thirdparty-order',
  templateUrl: './create-thirdparty-order.component.html',
  styleUrls: ['./create-thirdparty-order.component.css']
})
export class CreateThirdpartyOrderComponent implements OnInit {
  API = environment.api;
  restaurants: any = [];
  drivers: any = [];
  createForm: FormGroup;
  submitted = false;
  latitude: any;
  longitude: any;
  addressElement: HTMLInputElement = null;
  loadingVar: any;
  @ViewChild('searchbar', { read: ElementRef }) searchbar: ElementRef;
  restaurantDetails: any;
  localStorage = window.localStorage;

  res_lat: any;
  res_long: any;

  radius: any = environment.nearByConfig.radius;
  timer: any = environment.nearByConfig.timer;

  constructor(private afDb: AngularFireDatabase, private loadingService: LoaderService, public http: HttpClient, public router: Router, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.createForm = this.formBuilder.group({
      customer_name: ['', [Validators.required]],
      customer_mobile: [''],
      order_amount: ['', [Validators.required]],
      driver_id: [''],
      customer_address: ['', [Validators.required]],
      driver_s: '1'
    });

    this.restaurantDetails = JSON.parse(this.localStorage.getItem('restaurantDetails'));
    console.log("this.restaurantDetails", this.restaurantDetails);
    if (this.restaurantDetails && this.restaurantDetails.restaurant_id) {
      this.res_lat = this.restaurantDetails.res_lat;
      this.res_long = this.restaurantDetails.res_long;
      // this.getResturants().then(()=>{
      if (this.restaurantDetails.city == 'Scotland' || this.restaurantDetails.city == 'scotland' || this.restaurantDetails.city == 'Glasgow' || this.restaurantDetails.city == 'glasgow') {
        this.getDriversByCountry(230);
      } else if (this.restaurantDetails.city == 'Kolkata' || this.restaurantDetails.city == 'kolkata' || this.restaurantDetails.city == 'West Bengal') {
        this.getDriversByCountry(101);
      } else {
        this.getDrivers();
      }

      // });
    }

    let self = this;
    setTimeout(() => {
      self.initAutocomplete();
      self.driverLocationDBinit();
    }, 1000);

  }

  get f() { return this.createForm.controls; }

  initAutocomplete(): void {
    // reference : https://github.com/driftyco/ionic/issues/7223
    // this.addressElement = this.searchbar.nativeElement.querySelector('.searchbar-input');
    console.log(this.searchbar);
    this.addressElement = this.searchbar.nativeElement;
    this.createAutocomplete(this.addressElement).subscribe((location) => {
      console.log('Searchdata', location);

      // let options = {
      //   center: location,
      //   zoom: 10
      // };
      // this.map.setOptions(options);
      // this.addMarker(location, "Mein gesuchter Standort");

    });
  }

  createAutocomplete(addressEl: HTMLInputElement): Observable<any> {
    const autocomplete = new google.maps.places.Autocomplete(addressEl);
    // autocomplete.bindTo('bounds', this.map);
    return new Observable((sub: any) => {
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        console.log("place", place);
        if (!place.geometry) {
          // sub.error({
          //   message: 'Autocomplete returned place with no geometry'
          // });
          alert('Not Found! place not found');
        } else {
          console.log('Search Lat', place.geometry.location.lat());
          console.log('Search Lng', place.geometry.location.lng());
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.createForm.patchValue({ customer_address: place.formatted_address ? place.formatted_address : place.name });

          sub.next(place.geometry.location);
        }
      });
    });
  }

  driver_s_errors = false;
  autoAssignStatus = false;

  submit() {
    this.driver_s_errors = false;
    let self = this;
    this.submitted = true;
    this.loadingService.setLoading(true);
    if (this.createForm.invalid) {
      this.loadingService.setLoading(false);
      return;
    } else {
      console.log("done", this.createForm);
      this.loadingService.setLoading(false);
      if (this.restaurantDetails && this.restaurantDetails.restaurant_id) {
        if (this.createForm.value.driver_s == 2) {
          if (!this.createForm.value.driver_id) {
            this.driver_s_errors = true;
            return;
          }
          console.log("manual");
          return;
          let formData = new FormData();
          formData.append('customer_name', this.createForm.value.customer_name);
          formData.append('customer_mobile', this.createForm.value.customer_mobile);
          formData.append('order_amount', this.createForm.value.order_amount);
          formData.append('restaurant_id', this.restaurantDetails.restaurant_id);
          formData.append('driver_id', this.createForm.value.driver_id);
          formData.append('customer_address', this.createForm.value.customer_address);
          formData.append('latitude', this.latitude);
          formData.append('longitude', this.longitude);

          this.http.post(this.API + '/thirdPartyOrderCreate', formData).subscribe((res: any) => {
            console.log("res", res);
            this.loadingService.setLoading(false);
            if (res && res.status && res.extraData && res.restaurantDetails) {
              let obj = {
                type: 'thirdparty',
                driver_id: this.createForm.value.driver_id,
                restaurant_id: this.restaurantDetails.restaurant_id,
                order_amount: this.createForm.value.order_amount,
                customer_mobile: this.createForm.value.customer_mobile,
                customer_name: this.createForm.value.customer_name,
                date: new Date(),
                uba_orderid: res.data,
                uba_restaurantid: this.restaurantDetails.restaurant_id,
                restaurant_name: res.restaurantDetails[0].name,
                uba_restulat: res.restaurantDetails[0].res_lat,
                uba_restulong: res.restaurantDetails[0].res_long,
                uba_total: this.createForm.value.order_amount,
                uba_order_status: 1,
                driver_name: res.driverDetails[0].dt_name,
                driver_phone: res.driverDetails[0].dt_phone,
                order_status: 1,
                status_word: 'new',
                uba_driver: this.createForm.value.driver_id,
                uba_date: new Date(),
                uba_address: this.createForm.value.customer_address,
                uba_adsfrom: res.restaurantDetails[0].address,
                uba_lat: res.extraData.customer_lat,
                uba_long: res.extraData.customer_lng,
                uba_username: this.createForm.value.customer_name,
                uba_phone: this.createForm.value.customer_mobile,
                uba_distance: res.mile
              };

              console.log(obj);

              self.afDb.object('/driverOrders/' + self.createForm.value.driver_id + '/' + res.data).update(obj);
              this.router.navigate(['thirdparty-order-list']);
            } else {
              alert(res.message);
            }

          }, err => {
            console.log(err);
            this.loadingService.setLoading(false);
          });
        } else {
          console.log("Auto");
          this.autoAssignStatus = true;

          this.getDriversNearestRestaurant(parseFloat(this.res_lat), parseFloat(this.res_long), environment.nearByConfig.radius);
        }

      }
    }
  }

  driverLocationGeo: any;
  driverLocationDBinit() {
    var driverLocationDBRef = this.afDb.database.ref('driversLocation');
    this.driverLocationGeo = new geofire.GeoFire(driverLocationDBRef);
    console.log(this.driverLocationGeo);
  }

  displayMessage: any;
  nearestDriversAround: any = [];
  getDriversNearestRestaurant(latitude, longitude, radius) {
    this.nearestDriversAround = [];
    this.displayMessage = 'Getting nearest drivers...';
    let self = this;

    // if (self.orderSubscribe) {
    //   self.orderSubscribe.unsubscribe();
    // }


    console.log("getDriversNearestRestaurant lat long", latitude + ' ' + longitude);
    console.log('radius', radius);

    const geoQuery = this.driverLocationGeo.query({
      center: [latitude, longitude], //current user location
      radius //in kilometers
    });

    geoQuery.on("key_entered", (key, location, distance) => {
      console.log("key-location-distance", key + ' --- ' + location + ' --- ' + distance);
      self.afDb.database.ref("drivers/" + key).once("value", snap => {
        // console.log("snap", snap);
        self.drivers.forEach(element_d => {
          console.log("dt_id", element_d.dt_id);
          console.log("key", key)
          if (element_d.dt_id == key) {
            self.nearestDriversAround.push({ ...snap.val(), location: location, distance: distance, driverId: key });
          }
        });
        // this.nearestDriversAround.push({ ...snap.val(), location: location, distance: distance, driverId: key });
      });
    });

    geoQuery.on("ready", (key, location, distance) => {
      console.log("ready key-location-distance", key + ' --- ' + location + ' --- ' + distance);
      console.log(this.nearestDriversAround);
      // unsubscribe
      // self.orderSubscribe.unsubscribe();
      setTimeout(() => {
        self.sortByDistnance(self.nearestDriversAround)
      }, 1000);

      // send request to driver
      setTimeout(() => {
        self.sendRequestsToDriver();
      }, 3000);
    });

  }

  sortByDistnance(drivers) {
    drivers.sort(function (a, b) { return (a.distance > b.distance) ? 1 : ((b.distance > a.distance) ? -1 : 0); });
  }

  orderCreated = false;
  orderCreatedData: any;
  driverSearchTimer: any;
  requestAcceptedStatus = false;
  prevDriverIDStore: any;
  sendRequestsToDriver() {
    this.driverIndex = 0;
    let self = this;
    if (self.driverSearchTimer) {
      clearInterval(self.driverSearchTimer);
    }

    if (self.driverOrderSubscribe) {
      self.driverOrderSubscribe.unsubscribe();
    }

    console.log("sendRequestsToDriver", this.nearestDriversAround);
    if (this.nearestDriversAround.length > 0) {

      this.displayMessage = 'Assign order to the driver...';

      if (this.orderCreated == false) {
        let formData = new FormData();
        formData.append('customer_name', this.createForm.value.customer_name);
        formData.append('customer_mobile', this.createForm.value.customer_mobile);
        formData.append('order_amount', this.createForm.value.order_amount);
        formData.append('restaurant_id', this.restaurantDetails.restaurant_id);
        formData.append('driver_id', this.createForm.value.driver_id);
        formData.append('customer_address', this.createForm.value.customer_address);
        formData.append('latitude', this.latitude);
        formData.append('longitude', this.longitude);

        this.http.post(this.API + '/thirdPartyOrderCreate', formData).subscribe((res: any) => {
          console.log("res", res);
          this.loadingService.setLoading(false);
          if (res && res.status && res.extraData && res.restaurantDetails) {
            this.orderCreated = true;
            this.orderCreatedData = res;
            let obj = {
              type: 'thirdparty',
              driver_id: this.nearestDriversAround[0].driverId,
              restaurant_id: this.restaurantDetails.restaurant_id,
              order_amount: this.createForm.value.order_amount,
              customer_mobile: this.createForm.value.customer_mobile,
              customer_name: this.createForm.value.customer_name,
              date: new Date(),
              uba_orderid: this.orderCreatedData.data,
              uba_restaurantid: this.restaurantDetails.restaurant_id,
              restaurant_name: this.orderCreatedData.restaurantDetails[0].name,
              uba_restulat: this.orderCreatedData.restaurantDetails[0].res_lat,
              uba_restulong: this.orderCreatedData.restaurantDetails[0].res_long,
              uba_total: this.createForm.value.order_amount,
              uba_order_status: 1,
              // driver_name: this.orderCreatedData.driverDetails[0].dt_name,
              // driver_phone: this.orderCreatedData.driverDetails[0].dt_phone,
              order_status: 1,
              status_word: 'new',
              uba_driver: this.nearestDriversAround[0].driverId,
              uba_date: new Date(),
              uba_address: this.createForm.value.customer_address,
              uba_adsfrom: this.orderCreatedData.restaurantDetails[0].address,
              uba_lat: this.orderCreatedData.extraData.customer_lat,
              uba_long: this.orderCreatedData.extraData.customer_lng,
              uba_username: this.createForm.value.customer_name,
              uba_phone: this.createForm.value.customer_mobile,
              uba_distance: this.orderCreatedData.mile
            };

            console.log(obj);

            self.afDb.object('/driverOrders/' + self.nearestDriversAround[0].driverId + '/' + res.data).update(obj);
            // this.router.navigate(['thirdparty-order-list']);
            self.driverIndex++;
            this.prevDriverIDStore = self.nearestDriversAround[0].driverId;
            self.driverOrderSubscribe = self.afDb.object('/driverOrders/' + self.nearestDriversAround[0].driverId + '/' + this.orderCreatedData.data).subscribe(res => {
              console.log("driver res", res);
              if (res && res.order_status == "2") {
                console.log("accepted");
                self.stopSendingRequest();
                self.requestAcceptedStatus = true;
                self.displayMessage = "Driver accepted the request";
                self.orderUpdateDB(res.driver_id, this.orderCreatedData.data);
                // console.log("driver details", this.driverDetails);
                // if (res.uba_driver) {
                //   // self.getDriverDetails(res.uba_driver);
                // }

                /// message display remove
                // setTimeout(() => self.isVisible = false, 3000)
              }
            })

            this.driverAssign();

          } else {
            alert(res.message);
          }

        }, err => {
          console.log(err);
          this.loadingService.setLoading(false);
        });
      } else {
        let obj = {
          type: 'thirdparty',
          driver_id: this.nearestDriversAround[0].driverId,
          restaurant_id: this.restaurantDetails.restaurant_id,
          order_amount: this.createForm.value.order_amount,
          customer_mobile: this.createForm.value.customer_mobile,
          customer_name: this.createForm.value.customer_name,
          date: new Date(),
          uba_orderid: this.orderCreatedData.data,
          uba_restaurantid: this.restaurantDetails.restaurant_id,
          restaurant_name: this.orderCreatedData.restaurantDetails[0].name,
          uba_restulat: this.orderCreatedData.restaurantDetails[0].res_lat,
          uba_restulong: this.orderCreatedData.restaurantDetails[0].res_long,
          uba_total: this.createForm.value.order_amount,
          uba_order_status: 1,
          // driver_name: this.orderCreatedData.driverDetails[0].dt_name,
          // driver_phone: this.orderCreatedData.driverDetails[0].dt_phone,
          order_status: 1,
          status_word: 'new',
          uba_driver: this.nearestDriversAround[0].driverId,
          uba_date: new Date(),
          uba_address: this.createForm.value.customer_address,
          uba_adsfrom: this.orderCreatedData.restaurantDetails[0].address,
          uba_lat: this.orderCreatedData.extraData.customer_lat,
          uba_long: this.orderCreatedData.extraData.customer_lng,
          uba_username: this.createForm.value.customer_name,
          uba_phone: this.createForm.value.customer_mobile,
          uba_distance: this.orderCreatedData.mile
        };

        console.log(obj);
        this.prevDriverIDStore = self.nearestDriversAround[0].driverId;
        self.afDb.object('/driverOrders/' + self.nearestDriversAround[0].driverId + '/' + this.orderCreatedData.data).update(obj);
        // this.router.navigate(['thirdparty-order-list']);
        self.driverIndex++;
        self.driverOrderSubscribe = self.afDb.object('/driverOrders/' + self.nearestDriversAround[0].driverId + '/' + this.orderCreatedData.data).subscribe(res => {
          console.log("driver res", res);
          if (res && res.order_status == "2") {
            console.log("accepted");
            self.stopSendingRequest();
            self.requestAcceptedStatus = true;
            self.displayMessage = "Driver accepted the request";
            self.orderUpdateDB(res.driver_id, this.orderCreatedData.data);
            // console.log("driver details", this.driverDetails);
            // if (res.uba_driver) {
            //   // self.getDriverDetails(res.uba_driver);
            // }

            /// message display remove
            // setTimeout(() => self.isVisible = false, 3000)
          }
        })

        this.driverAssign();
      }


      // setTimeout(() => {
      //   self.autoAssignStatus = false;
      // }, 3000);

    } else {
      this.displayMessage = 'Drivers not available.';
      setTimeout(() => {
        self.autoAssignStatus = false;
      }, 3000);
    }

  }

  stopSendingRequest() {
    console.log("stopSendingRequest....");
    let self = this;
    if (self.driverSearchTimer) {
      clearInterval(self.driverSearchTimer);
    }
    if (self.driverOrderSubscribe) {
      self.driverOrderSubscribe.unsubscribe();
    }
    if (self.driverSearchTimer) {
      clearInterval(self.driverSearchTimer);
    }

  }

  removPrevRequest() {
    console.log("removPrevRequest...")
    let self = this;
    if (this.prevDriverIDStore) {
      // this.afDb.object('/requests/' + this.prevDriverIDStore + '/' + this.storage.localData.user_id + '/' + self.order_id).remove();
      this.afDb.object('/driverOrders/' + this.prevDriverIDStore + '/' + this.orderCreatedData.data).remove();
    }
    // if (self.resSubscribe) {
    //   self.resSubscribe.unsubscribe();
    // }
  }

  driverIndex: any = 0;
  driverAssign() {
    console.log("driverAssign...");
    let self = this;
    self.driverSearchTimer = setInterval(function () {
      console.log("driverIndex", self.driverIndex);

      if (self.nearestDriversAround.length < self.driverIndex + 1) {
        self.removPrevRequest();
        clearInterval(self.driverSearchTimer);
        self.displayMessage = 'Driver are not available at the moment. Please try again later';
        setTimeout(() => {
          self.autoAssignStatus = false;  
        }, 2000);
        
      } else {
        self.notifyDriver(self.nearestDriversAround[self.driverIndex]);
        self.driverIndex++;
      }
    }, self.timer);
  }

  notifyDriver(driverDetails) {
    console.log("notifyDriver...");
    let self = this;
    console.log("driverDetails", driverDetails);
    this.removPrevRequest();

    if (driverDetails.driverId) {
      let obj = {
        type: 'thirdparty',
        driver_id: this.nearestDriversAround[self.driverIndex].driverId,
        restaurant_id: this.restaurantDetails.restaurant_id,
        order_amount: this.createForm.value.order_amount,
        customer_mobile: this.createForm.value.customer_mobile,
        customer_name: this.createForm.value.customer_name,
        date: new Date(),
        uba_orderid: this.orderCreatedData.data,
        uba_restaurantid: this.restaurantDetails.restaurant_id,
        restaurant_name: this.orderCreatedData.restaurantDetails[0].name,
        uba_restulat: this.orderCreatedData.restaurantDetails[0].res_lat,
        uba_restulong: this.orderCreatedData.restaurantDetails[0].res_long,
        uba_total: this.createForm.value.order_amount,
        uba_order_status: 1,
        // driver_name: this.orderCreatedData.driverDetails[0].dt_name,
        // driver_phone: this.orderCreatedData.driverDetails[0].dt_phone,
        order_status: 1,
        status_word: 'new',
        uba_driver: this.nearestDriversAround[self.driverIndex].driverId,
        uba_date: new Date(),
        uba_address: this.createForm.value.customer_address,
        uba_adsfrom: this.orderCreatedData.restaurantDetails[0].address,
        uba_lat: this.orderCreatedData.extraData.customer_lat,
        uba_long: this.orderCreatedData.extraData.customer_lng,
        uba_username: this.createForm.value.customer_name,
        uba_phone: this.createForm.value.customer_mobile,
        uba_distance: this.orderCreatedData.mile
      };

      console.log(obj);
      this.prevDriverIDStore = self.nearestDriversAround[self.driverIndex].driverId;
      self.afDb.object('/driverOrders/' + self.nearestDriversAround[self.driverIndex].driverId + '/' + this.orderCreatedData.data).update(obj);
      // this.router.navigate(['thirdparty-order-list']);

      self.driverOrderSubscribe = self.afDb.object('/driverOrders/' + self.nearestDriversAround[self.driverIndex].driverId + '/' + this.orderCreatedData.data).subscribe(res => {
        console.log("driver res", res);
        if (res && res.order_status == "2") {
          console.log("accepted");
          self.stopSendingRequest();
          self.requestAcceptedStatus = true;
          self.displayMessage = "Driver accepted the request";
          self.orderUpdateDB(res.driver_id, this.orderCreatedData.data);
        }
      });
    }

  }

  driverOrderSubscribe: any;
  ngOnDestroy() {
    if (this.driverOrderSubscribe) {
      this.driverOrderSubscribe.unsubscribe();
    }
  }

  orderUpdateDB(driverId, order_id) {
    let formData = new FormData();
    formData.append('driverId', driverId);
    formData.append('order_id', order_id);

    this.http.post(this.API + '/thirdparty-order-update-with-driver', formData).subscribe((res: any) => {
      setTimeout(() => {
        this.router.navigate(['thirdparty-order-list']);
      }, 4000);
    });
  }


  async getResturants() {
    this.http.get(this.API + '/restaurantsList').subscribe((res: any) => {
      if (res && res.status && res.data) {
        this.restaurants = res.data;
        console.log(this.restaurants);

      }
    });
  }

  async getDrivers() {
    this.http.get(this.API + '/driverList').subscribe((res: any) => {
      if (res && res.status && res.data) {
        this.drivers = res.data;
        console.log(this.drivers);
      }
    });
  }

  async getDriversByCountry(countryId) {
    this.http.get(this.API + '/driverList/' + countryId).subscribe((res: any) => {
      if (res && res.status && res.data) {
        this.drivers = res.data;
        console.log(this.drivers);
      }
    });
  }

}
