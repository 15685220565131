import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  // localStorage: Storage;
  localStorage = window.localStorage;
  restaurantDetails: any;
  logout: any = false;;

  constructor() { }

  ngOnInit() {
    // this.localStorage.removeItem('name');
    // this.localStorage.setItem('name','kalyan');
    // console.log(this.localStorage.getItem('name'));
    console.log(this.localStorage.getItem('token'));
    console.log(this.localStorage.getItem('restaurantslug'));
    console.log(JSON.parse(this.localStorage.getItem('restaurantDetails')));
    this.restaurantDetails = JSON.parse(this.localStorage.getItem('restaurantDetails'));
    if(this.restaurantDetails && this.restaurantDetails.restaurant_id){
      this.logout = false;
    }else{
      this.logout = true;
    }
  }

}
