import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../loader.service';
import { AngularFireDatabase } from 'angularfire2/database';

@Component({
  selector: 'app-thirdparty-order-list',
  templateUrl: './thirdparty-order-list.component.html',
  styleUrls: ['./thirdparty-order-list.component.css']
})
export class ThirdpartyOrderListComponent implements OnInit {
  API = environment.api;
  orderList: any = [];
  drivers: any = [];
  restaurants: any = [];
  reCall: any;
  localStorage = window.localStorage;
  restaurantDetails: any;

  constructor(private loadingService: LoaderService, private afDb: AngularFireDatabase, public http: HttpClient, public router: Router) { }

  ngOnInit() {
    console.log(JSON.parse(this.localStorage.getItem('restaurantDetails')));
    this.restaurantDetails = JSON.parse(this.localStorage.getItem('restaurantDetails'));
    // this.loadingService.setLoading(true);

    if(this.restaurantDetails && this.restaurantDetails.restaurant_id){
      this.getOrderList();
      let self = this;
      this.reCall = setInterval(()=>{
        self.getOrderListReCall();
      }, 10000);
    }
  }

  ngOnDestroy() {
    clearInterval(this.reCall);
  }

  async getOrderList() {
    this.loadingService.setLoading(true);
    this.http.get(this.API + '/thirdPartyOrderList/'+this.restaurantDetails.restaurant_id).subscribe((res: any) => {
      this.loadingService.setLoading(false);
      if (res && res.status && res.data) {
        this.orderList = res.data;
        console.log(this.orderList);
        this.restaurants = res.restaurants ? res.restaurants : [];
        this.drivers = res.drivers ? res.drivers : [];
      }
    }, err=>{
      console.log(err);
      this.loadingService.setLoading(false);
    });
  }
  
  async getOrderListReCall() {
    this.http.get(this.API + '/thirdPartyOrderList/'+this.restaurantDetails.restaurant_id).subscribe((res: any) => {
      if (res && res.status && res.data) {
        this.orderList = res.data;
        console.log(this.orderList);
        this.restaurants = res.restaurants ? res.restaurants : [];
        this.drivers = res.drivers ? res.drivers : [];
      }
    }, err=>{
      console.log(err);
    });
  }

  statusChange(order, e){
    console.log(e.target.value);
    console.log(order);
    let self = this;

    let data = {
      'order_status': e.target.value,
      'uba_order_status': e.target.value,
    }
    // self.afDb.object('/orders/' + order.uba_orderid).update(data);
    self.afDb.object('/driverOrders/' + order.driver_id + '/' + order.restaurant_id).update(data);

    let formData = new FormData();
    formData.set('status', e.target.value);
    formData.set('restaurant_id', this.restaurantDetails.restaurant_id);

    this.http.post(this.API + '/thirdparty-order-status-update', formData).subscribe((res)=>{
      console.log(res);
    })
  }

}
