import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css']
})
export class RedirectComponent implements OnInit {
  token: any;
  restaurantslug: any;
  localStorage = window.localStorage;
  API = environment.api;
  logout: any = false;

  constructor(private route: ActivatedRoute, private router: Router, public http: HttpClient) { }

  ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('token');
    this.restaurantslug = this.route.snapshot.paramMap.get('restaurantslug');

    console.log(this.token);
    console.log(this.restaurantslug);

    if (this.token && this.restaurantslug) {
      this.getRestaurantDetails();
    }
  }

  getRestaurantDetails() {
    let formData = new FormData();
    formData.set('token', this.token);
    formData.set('restaurantslug', this.restaurantslug);
    this.http.post(this.API + '/restaurant-validate', formData).subscribe((res: any) => {
      console.log("res", res);
      if (res && res.status) {
        localStorage.setItem('token', this.token);
        localStorage.setItem('restaurantslug', this.restaurantslug);
        localStorage.setItem('restaurantDetails', JSON.stringify(res.data));
        this.router.navigate(['/']);
      }else{
        this.logout = true;
      }
    });
  }

}
