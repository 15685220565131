import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { HomeComponent } from './home/home.component';
import { appRoutingModule } from './app.routing';
import { OrderListComponent } from './order-list/order-list.component';
import { OrderTrackingComponent } from './order-tracking/order-tracking.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { OrderProcessingComponent } from './order-processing/order-processing.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { HeaderComponent } from './header/header.component';
// import { StorageServiceModule } from 'ngx-webstorage-service';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrderAssignComponent } from './order-assign/order-assign.component';
import { CreateThirdpartyOrderComponent } from './create-thirdparty-order/create-thirdparty-order.component';
import { ThirdpartyOrderListComponent } from './thirdparty-order-list/thirdparty-order-list.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { RedirectComponent } from './redirect/redirect.component';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    OrderListComponent,
    OrderTrackingComponent,
    OrderDetailsComponent,
    OrderProcessingComponent,
    NavBarComponent,
    SideNavComponent,
    HeaderComponent,
    OrderAssignComponent,
    CreateThirdpartyOrderComponent,
    ThirdpartyOrderListComponent,
    SpinnerComponent,
    RedirectComponent
  ],
  imports: [
    BrowserModule,
    appRoutingModule,
    // StorageServiceModule
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
